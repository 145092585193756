import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Load Styles
import '../scss/main.scss';


// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

// require('bootstrap-icons/font/bootstrap-icons.css');
import '../../node_modules/@fortawesome/fontawesome-free/css/brands.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/regular.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/solid.css';
import '../../node_modules/@fortawesome/fontawesome-free/js/all.js';
// import FastGlob from 'fast-glob';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  once: true
});

  // import Swiper bundle with all modules installed
  import Swiper from 'swiper/bundle';

  // import styles bundle
  import 'swiper/css/bundle';

  // import videojs from 'video.js';


// Your app code
// console.log(`Hello ${process.env.HELLO}`);

$(document).ready(function() {

  // Hero section
  $(window).on('scroll', function() {
    if($(document).scrollTop() > 70) {
      $('.hero-section').addClass('hero-section--shrinked');
    }
    // else {
    //   $('.hero-section').removeClass('hero-section--shrinked');
    // }
  })

  // FAQs
  $(".set > a").on("click", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).siblings(".content").slideUp(200);
      $(".set > a i").removeClass("fa-minus").addClass("fa-plus");
    } else {
      $(".set > a i").removeClass("fa-minus").addClass("fa-plus");
      $(this).find("i").removeClass("fa-plus").addClass("fa-minus");
      $(".set > a").removeClass("active");
      $(this).addClass("active");
      $(".content").slideUp(200);
      $(this).siblings(".content").slideDown(200);
    }
  });

  var swiperTestimonial = new Swiper(".swiper--testimonial", {
    // Optional parameters
    direction: "horizontal",
    loop: true,
    autoplay: true,
    pauseOnMouseEnter: true,
    speed: 1000,
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderCustom: function (swiper, current, total) {
        var names = [];
        $(".swiper-wrapper .swiper-slide").each(function (i) {
          names.push($(this).data("name"));
        });
        var text = "<ul>";
        for (let i = 1; i <= total; i++) {
          if (current == i) {
            text += `<li class="swiper-pagination-bullet active">${names[i]}</li>`;
          } else {
            text += `<li class="swiper-pagination-bullet">${names[i]}</li>`;
          }
        }
        text += "</ul>";
        return text;
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // discover with me swiper
  var swiperDiscoverPersonal = new Swiper(".swiper--discover-with-me", {
    // Optional parameters
    direction: "horizontal",
    loop: true,
    autoplay: true,
    pauseOnMouseEnter: true,
    speed: 1000,
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderCustom: function (swiper, current, total) {
        var names = [];
        $(".swiper-wrapper .swiper-slide").each(function (i) {
          names.push($(this).data("name"));
        });
        var text = "<ul>";
        for (let i = 1; i <= total; i++) {
          if (current == i) {
            text += `<li class="swiper-pagination-bullet active">${names[i]}</li>`;
          } else {
            text += `<li class="swiper-pagination-bullet">${names[i]}</li>`;
          }
        }
        text += "</ul>";
        return text;
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    // Creative
    effect: 'creative',
    creativeEffect: {
      prev: {
        // will set `translateZ(-400px)` on previous slides
        translate: ['-100%', 0, 0],
        scale: 0,
        opacity: 1
      },
      next: {
        // will set `translateX(100%)` on next slides
        translate: ['25%', 0, 0],
        opacity: 0.5,
        scale: 0.8
      },
    },
  });

  // auto tab system
  // var setIntervalDuration = ; // inerval duration in millisecond
  var appsTabContentCounter = 0;
  var curatedTabContentCounter = 0;
  var smartHomeTabContentCounter = 0;

  // Apps section tab
  var navTabs = $('.apps-section .nav-tab__btn--tab-control');
  var tabContentBlocks = $('.apps-section .tab-content-block');

  var appsTabElArray = [navTabs, tabContentBlocks];

  $.each(navTabs, function(idx, val) {
    $(this).on('click', function() {
      var getData = $(this).attr('data-target');
      $('.apps-section .nav-tab__btn--tab-control').removeClass('active');
      $(this).addClass('active');

      tabContentBlocks.each(function() {
        if($(this).attr('data-category') == getData) {
          $(this).addClass('active');
        }
        else {
          $(this).removeClass('active');
        }
      })

      // isTabClicked = true;
      appsTabContentCounter = idx;
      
    })
  })

  $('.apps-section').on('mouseenter', function() {
    setInterval(function() {
                    // reseting app tab counter value
                    if(appsTabContentCounter < navTabs.length) {
                      autoPlayTab(appsTabElArray,appsTabContentCounter);
                      appsTabContentCounter++;
                    }
                    else {
                      appsTabContentCounter = 0;
                    }
    }, 10000)
  })


  // auto tab system curated recommendation
  var curatedNavTabs = $('.curated-recom-section .nav-tab__btn--control');
  var curatedBgImages = $('.curated-recom-section .bg-images-list img');
  var curatedContentImages = $('.curated-recom-section .content-images-list img');

  var curatedTabElArray = [curatedNavTabs, curatedBgImages, curatedContentImages];

  $.each(curatedNavTabs, function(idx,val) {
    $(this).on('click', function() {
      // console.log('clicked');
      $('.curated-recom-section .curated-recom-section .nav-tab__btn--control').removeClass('active');
      $(this).addClass('active');
      controlActiveElement(curatedBgImages, idx);
      controlActiveElement(curatedContentImages, idx);
      autoPlayTab(curatedTabElArray,idx);

      curatedTabContentCounter = idx;
    })
  })

  $('.curated-recom-section').on('mouseenter', function() {
    setInterval(function() {
                    // resetting curated tab counter value
                    if(curatedTabContentCounter < curatedNavTabs.length) {
                      autoPlayTab(curatedTabElArray,curatedTabContentCounter);
                      curatedTabContentCounter++;
                    }
                    else {
                      curatedTabContentCounter = 0;
                    }
    }, 10000)
  })



  // Smart Home tab
  var smartHomeNavTabs = $('.smart-home-section .smart-home-tab button');
  var smartHomeImagesList = $('.smart-home-section .images-list img');
  var smartHomeTabElArray = [smartHomeNavTabs, smartHomeImagesList];

  $.each(smartHomeNavTabs, function(idx, val) {
    $(this).on('click', function() {
      var getCat = $(this).attr('data-target');

      smartHomeImagesList.each(function() {
        if($(this).attr('data-category') == getCat) {
          $(this).addClass('active');
        }
        else {
          $(this).removeClass('active');
        }
      })

      smartHomeTabContentCounter = idx;
    })
  })

  $('.smart-home-section').on('mouseenter', function() {
    setInterval(function() {
                        // resetting smart home tab counter value
                        if(smartHomeTabContentCounter < smartHomeNavTabs.length) {
                          autoPlayTab(smartHomeTabElArray ,smartHomeTabContentCounter);
                          smartHomeTabContentCounter++;
                        }
                        else {
                          smartHomeTabContentCounter = 0;
                        }
    }, 10000);
  })


  // Dynamic control active element function
  function controlActiveElement(el, idx) {
    $.each(el, function(index, val) {
      if(idx == index) {
        $(this).addClass('active');
      }
      else {
        $(this).removeClass('active');
      }
    })
  }

    // Dynamic Autoplay tab function
    function autoPlayTab(elArr, countVal) {
      for(var i = 0; i < elArr.length; i++) {
        $.each(elArr[i], function(idx, val) {
          if(idx == countVal) {
            $(this).addClass('active');
          }
          else {
            $(this).removeClass('active');
          }
      })
      }
    }


});


